/* Card.css */
.pretty-card {
    color: black;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: lightblue;
    transition: transform 0.2s;
}

.pretty-card:hover {
    transform: scale(1.05);
}
