table {
  border: solid 1px #DDEEEE;
  border-collapse: collapse;
}
td {
  border: solid 1px #DDEEEE;
  padding: 10px;
}

button {
  margin-bottom: 0px;
}

dt {
  margin: 5px;
  font-size: 1.25em;
}
dd {
  margin-bottom: 15px;
  margin-left: 0px;
}

table.configuration span.disabled {
  opacity: 0.5;
  margin-right: 10px;
}

table.configuration a {
  margin-right: 10px;
}
